<template>
    <BT-Blade-Item
        bladeName="browse-courier"
        :loadingMsg="loadingMsg"
        navigation="public-courier" 
        @fetched="itemFetched"
        :canSave="false">
        <template v-slot="{ item }">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-img
                            :src="companyLogoURL(item)"
                            class="mx-auto my-3"
                            rounded
                            width="150"
                            height="150">
                            <template v-slot:placeholder>
                                <v-icon size="100" color="primary">mdi-map-marker-distance</v-icon>
                            </template>
                        </v-img>   
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-card-title align-center justify-center>{{ item.companyName }}</v-card-title>
            
                        <v-card-text>
                            {{ item.publicDescription ? item.publicDescription : 'Another courier using the BlitzIt Web Platform for their small business.' }}
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
            
            <BT-Btn-Row v-if="!isMe">
                <template v-slot:left>
                    <BT-Btn
                        v-if="agreement != null"
                        bladeName="courier"
                        icon="mdi-account"
                        :id="agreement.id"
                        label="Open Courier"
                        navigation="couriers" />
                </template>
                <template v-slot:right>
                    <BT-Btn
                        v-if="item.primaryEmail != null"
                        @click="email(item)"
                        icon="mdi-email"
                        label="Email" />
                    <BT-Btn
                        v-if="item.phoneNumber != null"
                        @click="phone(item)"
                        icon="mdi-phone"
                        label="Phone" />

                    <BT-Btn v-if="item.website != null"
                        @click="website(item)"
                        icon="mdi-web"
                        label="Website" />

                    <BT-Btn
                        v-if="currentRequest != null"
                        class="error"
                        @click="cancelRequest"
                        icon="mdi-close"
                        label="Cancel Request" />
                    <BT-Btn
                        v-else-if="agreement == null && canRequest"
                        class="primary"
                        @click="request"
                        icon="mdi-human-greeting-proximity"
                        label="Request To Connect" />
                </template>
            </BT-Btn-Row>

            <v-divider />

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Public-Courier-Blade',
    data: () => {
        return {
            loadingMsg: null,
            currentItem: null,
            currentRequest: null,
            agreement: null
        }
    },
    computed: {
        canRequest() {
            return this.$BlitzIt.auth.session.isLoggedIn &&
                this.$BlitzIt.auth.session.data?.companyAccountID != this.currentItem?.id &&
                this.currentItem?.isOpenToAgreementRequests
        },
        isMe() {
            return this.$BlitzIt.auth.session.isLoggedIn &&
                this.$BlitzIt.auth.session.data?.companyAccountID == this.currentItem?.id
        }
    },
    methods: {
        email(item) {
            window.open('mailto:' + item.primaryEmail);
        },
        phone(item) {
            window.open('tel:' + item.phoneNumber);
        },
        website(item) {
            window.open(item.website, '_blank');
        },
        async itemFetched(item) {
            this.currentItem = item;

            var res = await this.$BlitzIt.store.get('couriers', item.id, { includeDetails: false, supplierID: item.id }, false);
            console.log(res);
            this.agreement = res;

            if (this.agreement == null) {
                this.currentRequest = await this.$BlitzIt.store.getAll('courier-requests', { sellerID: item.id });
            }
        },
        request() {
            this.loading = true;            
            this.$BlitzIt.store.post(
                'courier-requests',
                { sellerID: this.currentItem.id }
            )
            .then(res => {
                this.loading = false;
                this.currentRequest = res;
            })
            .catch(err => {
                console.log(err);
                this.loading = false;
            })
        },
        cancelRequest() {
            this.loading = true;            
            this.$BlitzIt.store.delete('courier-requests', this.currentRequest.id)
                .then(() => {
                    this.currentRequest = null;                    
                    this.loading = false;
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                })
        }
    }
}
</script>